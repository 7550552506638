import { useAddSomeTagToHTML } from "hooks/useAddTagToHtml";

export const GoogleMetrics = ({id}: {id: string}) => {

  useAddSomeTagToHTML(
    "script",
    "head",
    "afterbegin",
    `function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${id}');`
  );

  useAddSomeTagToHTML(
    "script",
    "body",
    "beforeend",
    `(() => {
            const iframe = document.createElement('iframe')
            iframe.src = 'https://www.googletagmanager.com/ns.html?id=${id}'
            iframe.height = 1
            iframe.width = 1
            iframe.style.display = 'none'
            const noscript = document.createElement('noscript')
            noscript.insertAdjacentElement('beforeend', iframe);
            return document.body.insertAdjacentElement('beforeend', noscript);
        })()`
  );

  return null;
};
