import { useEffect } from 'react';

type TParentTag = 'body' | 'head';

export const useAddSomeTagToHTML = <K extends keyof HTMLElementTagNameMap>(
    tagName: K,
    parentTag: TParentTag,
    where: InsertPosition,
    innerHTML: string
) => {
    useEffect(() => {
        const tag = document.createElement(tagName);

        tag.innerHTML = innerHTML;

        document[parentTag].insertAdjacentElement(where, tag);
    }, [tagName, innerHTML, where, parentTag]);
};
