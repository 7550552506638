import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import InfiniteScroll from "react-infinite-scroll-component";
import { ExternalContent } from "ui/ExternalContent";
import { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import { useGetPopupsMutation } from "redux/api/userApi";
import { useTypedSelector } from "hooks/useTypedRedux";
import { PopupModal } from "components/modals/PopupModal";
import { useDispatch } from "react-redux";
import { resetPopups } from "redux/slices/userSlice";

export const MessagesWrapper = () => {
  const { i18n } = useTranslation();
  const [modalPopupId, setModalPopupId] = useState(-1);
  const [page, setPage] = useState(1);
  const { popups } = useTypedSelector((state) => {
    return {
      popups: state.userSlice.popups,
    };
  });
  const dispatch = useDispatch();
  const [getPopups] = useGetPopupsMutation();
  const lang = i18n.language;

  const { isMobile } = useTypedSelector((state) => {
    return {
      isMobile: state.appSlice.isMobile,
    };
  });

  useEffect(() => {
    dispatch(resetPopups());
    getPopups({
      lang,
      page: 1,
      order: "createdAt",
      order_type: -1,
    }).then(() => {
      setPage(2);
    });
  }, [dispatch, getPopups, lang]);

  const hasMore = useMemo(() => popups.rows.length <= popups.count, [popups]);

  const loadMorePopups = () => {
    if (hasMore) {
      getPopups({
        lang,
        page,
        order: "createdAt",
        order_type: -1,
      }).then(() => {
        setPage((prevState) => prevState + 1);
      });
    }
  };

  const handleCloseModal = useCallback(() => {
    setModalPopupId(-1);
  }, []);

  const handleClick = (id: number) => {
    setModalPopupId(id);
  };

  return (
    <>
      <Container isMobile={isMobile}>
        <InfiniteScroll
          next={loadMorePopups}
          hasMore={hasMore}
          loader={<></>}
          dataLength={popups.rows.length}
          scrollableTarget={"messages_list"}
        >
          <List id="messages_list" isMobile={isMobile}>
            {popups.rows.map((message: any, index: number) => (
              <Item
                unread={message.isViewed}
                key={message.createdAt + index}
                onClick={() => handleClick(message.id)}
              >
                <ItemLeftBlock>
                  <MessagesTitle isMobile={isMobile}>
                    {message.title}
                  </MessagesTitle>
                  <MessagesDescription isMobile={isMobile}>
                    <ExternalContent content={message.content} />
                  </MessagesDescription>
                </ItemLeftBlock>
                <ItemRightBlock>
                  <MessagesDate isMobile={isMobile} unread={message.isViewed}>
                    {format(new Date(message.createdAt), "dd.MM.yyyy")}
                  </MessagesDate>
                  {!message.isViewed && <MessagesStatus />}
                </ItemRightBlock>
              </Item>
            ))}
          </List>
        </InfiniteScroll>
      </Container>
      <Suspense fallback={<></>}>
        <PopupModal
          popupId={modalPopupId}
          onClose={handleCloseModal}
          popups={popups}
        />
      </Suspense>
    </>
  );
};

const Container = styled.div<{ isMobile: boolean }>`
  width: 100%;
  margin: ${({ isMobile }) => (isMobile ? "-10px 0 20px" : "0")};

  & > div {
    width: 100%;
  }
`;

const List = styled.ul<{ isMobile: boolean }>`
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 654px;
  overflow-y: scroll;
  padding: 0;

  @media (max-width: 768px) {
    width: 500px;
  }

  @media (max-width: 600px) {
    width: 400px;
  }

  @media (max-width: 425px) {
    width: auto;
  }
`;

const Item = styled.li<{ unread: boolean }>`
  background-color: #363636;
  opacity: ${({ unread }) => (unread ? 0.3 : 1)};
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  gap: 10px;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.secondary.main};

  @media (hover: hover) {
    &:hover {
      border: 1px solid ${({ theme }) => theme.primary.main};
    }
  }
`;

const ItemLeftBlock = styled.div`
  width: 809px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (max-width: 1280px) {
    width: 500px;
  }

  @media (max-width: 1024px) {
    width: 590px;
  }

  @media (max-width: 768px) {
    width: 100%;
    max-width: calc(100% - 100px);
  }
`;

const ItemRightBlock = styled.div`
  max-width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: end;
`;

const MessagesTitle = styled.h4<{ isMobile: boolean }>`
  font-size: ${({ isMobile }) => (isMobile ? "16px" : "24px")};
  font-weight: 400;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  &:first-letter {
    text-transform: uppercase;
  }
`;

const MessagesDescription = styled.div<{ isMobile: boolean }>`
  font-size: ${({ isMobile }) => (isMobile ? "14px" : "18px")};
  word-break: break-all;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  * > {
    //overflow: hidden;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

const MessagesDate = styled.span<{ isMobile: boolean, unread: boolean }>`
  font-size: ${({ isMobile }) => (isMobile ? "14px" : "16px")};
  color: ${({ theme, unread }) => !unread ? theme.secondary.focused : theme.text.white};
`;

const MessagesStatus = styled.div`
  width: 21px;
  height: 21px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.primary.main};
`;
