import React, {useEffect} from 'react';
import styled, {css} from "styled-components";
import {ReactComponent as GoldIcon} from 'assets/images/jackpot_star_gold_icon.svg';
import {ReactComponent as BronzeIcon} from 'assets/images/jackpot_star_bronze_icon.svg';
import {ReactComponent as SilverIcon} from 'assets/images/jackpot_star_silver_icon.svg';
import {useGetJackpotMutation} from "../redux/api/appApi";
import {useTypedDispatch, useTypedSelector} from "../hooks/useTypedRedux";
import {useSocket} from "../services/SocketService";
import {updateJackpot} from "../redux/slices/appSlice";
import {useTranslation} from "react-i18next";
import {jackpotSelector} from "../redux/selectors/appSelector";
import {device} from "../constants/deviceSizes";

const JackpotStarsMobile = () => {
        const {t, i18n} = useTranslation();

        const jackpot = useTypedSelector(jackpotSelector)

        const socket = useSocket();
        const dispatch = useTypedDispatch();

        useEffect(() => {
            socket.on("jackpot", data => {
                dispatch(updateJackpot(data))
            })
        }, [])

        const stars = [
            {
                icon: <Gold/>,
                title: <div style={{color: '#B39942'}}>{t(`pages.games.grand`)}</div>,
                key: 'gold'
            },
            {
                icon: <Silver/>,
                title: <div style={{color: '#868480'}}>{t(`pages.games.minor`)}</div>,
                key: 'silver'
            },
            {
                icon: <Bronze/>,
                title: <div style={{color: '#835E48'}}>{t(`pages.games.mini`)}</div>,
                key: 'bronze'
            }
        ]
        const isRight = i18n.dir() === 'rtl';
        return (
            <Wrapper>
                {jackpot.length && stars
                    .slice(0, jackpot.length)
                    .map((star, index) => {
                        let currJackpot = jackpot.find(jack => jack.name === star.key);
                        return <React.Fragment key={`jackpot-${star.key}`}>
                            {currJackpot
                                ? <Icon>
                                    <Text>
                                        <div>{star.title}</div>
                                        <MoneyText isRight={isRight}>{currJackpot.currentJackpot} TND</MoneyText>
                                        <div>{t(`pages.games.winner`)}:</div>
                                        <div>{currJackpot.lastWinner}</div>
                                    </Text>
                                    {star.icon}
                                </Icon>
                                : <div/>}
                        </React.Fragment>
                    })}
            </Wrapper>
        );
    }
;

export default JackpotStarsMobile;

const MoneyText = styled.div<{ isRight: boolean }>`
  color: black;
  line-height: 16px;
  direction: ${({isRight}) => isRight ? 'rtl' : 'ltr'};
  font-size: 14px;
  font-weight: 900;
`

const Wrapper = styled.div`
  z-index: 100;
  position: absolute;
  width: 30%;
  height: 100%;
  top: -40px;
  scale: 1.4;
  left: 20%;

  > :nth-child(1) {
    transform: translate(-45%);
  }

  > :nth-child(2) {
    transform: translate(35%);
  }

  > :nth-child(3) {
    transform: translate(115%);
  }

  @media (${device.mobileL}) {
    scale: 1;

    > :nth-child(1) {
      transform: translate(-50%);
    }

    > :nth-child(2) {
      transform: translate(50%);
    }

    > :nth-child(3) {
      transform: translate(150%);
    }
  }

`

const Text = styled.div`
  color: white;
  //text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  font-size: 10px;
  z-index: 100;
  position: absolute;
  padding-top: 20px;
  transform: translate(50%, 50%);
  right: 50%;
  bottom: 50%;
  text-align: center;
  text-transform: uppercase;
`

const Icon = styled.div`
  display: flex;
  right: 0;
  position: absolute;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;

  & svg {
    animation: pulse 1s linear 0s infinite;

    @keyframes pulse {
      0% {
        transform: scale(1);
      }
      15% {
        transform: scale(1.1);
      }
      40% {
        transform: scale(1);
      }
      100% {
        transform: scale(1);
      }
    }
  }
`

const Gold = styled(GoldIcon)`

`

const Silver = styled(SilverIcon)`
  animation-delay: 0.3333s !important;
`

const Bronze = styled(BronzeIcon)`
  animation-delay: 0.6667s !important;
`
