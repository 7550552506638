import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
import { store } from "./redux/store";
import { getTheme } from "./theme";
import { ErrorBoundary } from "react-error-boundary";
import ErrorBoundaryPage from "pages/ErrorBoundaryPage";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

function Error() {
    return (
        <ErrorBoundaryPage />
    );
}

root.render(
    <ThemeProvider theme={getTheme}>
        <ErrorBoundary FallbackComponent={Error}>
            <Provider store={store}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </Provider>
        </ErrorBoundary>
    </ThemeProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();


reportWebVitals();

