import {BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, retry} from '@reduxjs/toolkit/query/react';
import {resetAdmin} from "../slices/userSlice";
import {getStorage} from "../../hooks/useStorage";
import settings from "settings";
interface CustomError {
    data: {
        message: string
        error?: boolean
        field?: string
        code?: number
        errors?: {
            location: string
            msg: string
            param: string
            value: any
            code: number
        }[]
    },
    status: number
}

interface CustomFetchArgs extends RequestInit {
    url: string;
    params?: Record<string, any>;
    body?: any;
    responseHandler?: 'json' | 'text' | ((response: Response) => Promise<any>) | string;
    validateStatus?: (response: Response, body: any) => boolean;
}
const {SETTINGS} = settings;
const mirror = Object.keys(SETTINGS.MIRRORS).find(item => item === window.location.host);
export const BASE_URL = settings.NODE_ENV === "development" ? SETTINGS.API_DEV_URL : mirror ? SETTINGS.MIRRORS[`${mirror}`].API_PROD_URL :
    SETTINGS.API_PROD_URL;

export const APP_VERSION = process.env["VITE_APP_VERSION"];

export const BaseQueryInterceptor = retry(
    async (args: string | FetchArgs, api, extraOptions) => {
        const result = await fetchBaseQuery({
            baseUrl: `${BASE_URL}/api`,
            prepareHeaders: headers => {
                headers.set('Content-Type', 'application/json');
                return headers;
            },
            credentials: "include",
        })(args, api, extraOptions)
        if (result.error?.data === 'deleted' || result.error?.data === 'OK' || result.error?.data === 'Password recovered') return {data: result.error.data}
        if (result.error?.status === 401) {
            await fetchBaseQuery({baseUrl: `${BASE_URL}`,})
            ({url: 'api/logout', method: 'DELETE'}, api, extraOptions)
            api.dispatch(resetAdmin())

        }
        return result
    }, {maxRetries: 0,}
) as BaseQueryFn<string | CustomFetchArgs, unknown, CustomError>


export const commonAPI = createApi({
    reducerPath: 'API',
    baseQuery: BaseQueryInterceptor,
    endpoints: () => ({}),
});
