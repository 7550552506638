import React, { useEffect } from "react";
import Router from "./routes";
import settings from "./settings";
import { putStorage } from "./hooks/useStorage";
import "./i18n";
import SEO from "./components/seo";
import { YandexMetrics } from "services/YandexMetrics";
import { GoogleMetrics } from "services/GoogleMetrics";

const services =
  settings.NODE_ENV === "development"
    ? settings.SETTINGS.SERVICES_DEV
    : settings.SETTINGS.SERVICES_PROD;
const seoSettings = settings.SETTINGS.SEO;

const App = () => {
  const mirror = Object.keys(settings.SETTINGS.MIRRORS).find(
    (item) => item === window.location.host
  );
  const curSeoSettings = mirror
    ? settings.SETTINGS.MIRRORS[`${mirror}`].SEO
    : seoSettings;
  useEffect(() => {
    putStorage("APP_SETTINGS", settings, { silent: true });
    putStorage(
      "API_BASE_URL",
      settings.NODE_ENV === "development"
        ? settings.SETTINGS.API_DEV_URL
        : mirror
        ? settings.SETTINGS.MIRRORS[`${mirror}`].API_PROD_URL
        : settings.SETTINGS.API_PROD_URL,
      { silent: true }
    );
    putStorage("APP_SEO", curSeoSettings, { silent: true });
    putStorage("APP_ACCESS", settings.SETTINGS.ACCESS, { silent: true });
    putStorage("APP_OTHER", settings.SETTINGS.OTHER, { silent: true });
    putStorage("APP_LOADING", false, { silent: true });
    putStorage("APP_SERVICES", services);
  }, []);
  return (
    <>
      <SEO
        title={curSeoSettings.TITLE}
        siteNameKey={curSeoSettings.SITE_NAME_KEY}
        description={curSeoSettings.DESCRIPTION}
        favicon={curSeoSettings.FAVICON_PATH}
        keywords={curSeoSettings.KEYWORDS}
        imgPath={curSeoSettings.LOGO_PATH}
      />
      <Router />
      {}
      {process.env.REACT_APP_INSTANCE === "megawin" &&
        settings.NODE_ENV !== "development" && (
          <>
            <YandexMetrics id={+settings.SETTINGS.SERVICES_PROD.YM.ID} />
            <GoogleMetrics id={settings.SETTINGS.SERVICES_PROD.GM.ID} />
          </>
        )}
    </>
  );
};

export default App;
