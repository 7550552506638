import { YMInitializer } from "react-yandex-metrika";

export const YandexMetrics = ({ id }: { id: number }) => {
  return (
    <>
      <YMInitializer
        accounts={[id]}
        options={{
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
          webvisor: true,
        }}
      />
    </>
  );
};
