import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { getStorage } from "../../../hooks/useStorage";
import { ReactComponent as GamesIcon } from "assets/images/navbar/icon_games.svg";
import { ReactComponent as TvGamesIcon } from "assets/images/navbar/icon_tv_games.svg";
import { ReactComponent as FavoriteIcon } from "assets/images/navbar/footer_favorite_icon.svg";
import { ReactComponent as VirtualIcon } from "assets/images/navbar/footer_virtual_icon.svg";
import { ReactComponent as CrashIcon } from "assets/images/navbar/icon_crash.svg";
import { useTypedSelector } from "../../../hooks/useTypedRedux";
import { useCustomToast } from "../../../hooks/useCustomToast";
import { userSelector } from "../../../redux/selectors/userSelector";

interface IFooter {
  KEY: string;
  VISIBLE: boolean;
  PATH: string;
  ICON_PATH: string;
}

const FooterMobile = (): JSX.Element => {
  const { t } = useTranslation();

  const user = useTypedSelector(userSelector);

  const footerLinks = [
    {
      title: `${t(`common.footer.games`)}`,
      icon: <GamesIcon />,
      key: "GAMES",
      path: "/games",
      order: 1,
    },
    {
      title: `${t(`common.footer.tv_games`)}`,
      icon: <TvGamesIcon />,
      key: "TV_GAMES",
      path: "/tv-games",
      order: 2,
    },
    {
      title: `${t(`common.footer.favorite`)}`,
      icon: <FavoriteIcon />,
      key: "FAVORITE",
      path: "/games?category=favorite",
      order: 3,
    },
    {
      title: `${t(`common.footer.crash`)}`,
      icon: <CrashIcon />,
      key: "CRASH",
      path: "/games?category=Crash",
      order: 4,
    },
    {
      title: `${t(`common.footer.fast`)}`,
      icon: <CrashIcon />,
      key: "FAST",
      path: "/games?category=Fast",
      order: 4,
    },
    {
      title: `${t(`common.footer.virtual`)}`,
      icon: <VirtualIcon style={{ paddingTop: 5 }} />,
      key: "VIRTUAL",
      path: "/games?category=Virtual",
      order: 5,
    },
  ];

  const FOOTER = getStorage("APP_OTHER.MOBILE_FOOTER");
  const data = footerLinks.filter((item) => FOOTER[item.key]);
  const middle = data.find((item) => item.key === "FAVORITE");

  const isActive = (path: string) => {
    let pathname = window.location.pathname.toLowerCase();
    let itemPath = path.toLowerCase();
    let search = window.location.search.toLowerCase();
    const exclude = [
      "category=crash",
      "category=virtual",
      "category=favorite",
      "category=fast",
    ];
    if (exclude.some((item) => search.includes(item))) {
      let value = exclude.find((item) => search.includes(item));
      return value ? itemPath.includes(value) : false;
    }
    return (
      itemPath.includes(pathname) &&
      pathname !== "/" &&
      !exclude.some((item) => itemPath.includes(item))
    );
  };
  const callToast = useCustomToast({
    text: t("messages.alert.please_login"),
    type: "error",
  });

  const handleOpenMiddle = (e: any) => {
    // console.log(!user, !!user)
    if (!user) {
      callToast();
      e.preventDefault();
    }
  };

  return (
    <>
      <Wrapper>
        {data
          .filter((item) => item.key !== "FAVORITE")
          .map((item, index: number) => (
            <React.Fragment key={`footer-links-${index}-${item.key}`}>
              <StyledButton
                order={item.order}
                to={item.path}
                isActive={isActive(item.path)}
              >
                <Icon>{item.icon}</Icon>
                <Label>{item.title}</Label>
              </StyledButton>
            </React.Fragment>
          ))}
        <React.Fragment key={`footer-links-${5}-${middle?.key}`}>
          <MiddleButton
            order={middle?.order || 3}
            to={middle?.path || ""}
            isActive={isActive(middle?.path || "")}
            onClick={(event) => handleOpenMiddle(event)}
          >
            <div />
            <Label>{middle?.title}</Label>
            <Circle>
              <span />
              <MiddleIcon>{middle?.icon || <></>}</MiddleIcon>
            </Circle>
          </MiddleButton>
        </React.Fragment>
      </Wrapper>
    </>
  );
};

export default FooterMobile;

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  height: 64px;
  background-color: transparent;
  justify-content: center;

  > {
    :nth-of-type(1) {
      border-start-start-radius: 20px;
    }

    :nth-of-type(4) {
      border-start-end-radius: 20px;
    }
  }
`;

const Icon = styled.div`
  max-width: 40px;
  max-height: 37px;
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    height: auto;
  }
`;

const Circle = styled.div`
  display: block;
  width: 400px;
  height: 400px;
  overflow: hidden;
  position: absolute;
  top: 0;

  ::before {
    content: "";
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0);
    position: absolute;
    bottom: 240px;
    left: 50px;
    border: ${({ theme }) => `120px solid ${theme.secondary.main}`};
  }
`;

const StyledButton = styled(NavLink)<{ isActive?: boolean; order: number }>`
  margin: 0 -1px;
  box-sizing: border-box;
  display: flex;
  height: 64px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-self: self-end;
  padding: 9px 3px 5px;
  border: none;
  cursor: pointer;
  flex: 1;
  background-color: ${({ theme }) => theme.secondary.main};
  order: ${({ order }) => order};
  z-index: 1000;
  color: ${({ theme, isActive }) =>
    isActive ? theme.primary.light : theme.text.white};

  & div > svg > path {
    fill: ${({ theme, isActive }) =>
      isActive ? theme.primary.light : theme.text.white};
  }
`;

const MiddleButton = styled(StyledButton)<{ selected?: boolean }>`
  align-self: start;
  background-color: transparent;
  order: 3;
  height: 100%;
  z-index: 10;
`;

const MiddleIcon = styled(Icon)`
  background-color: #363636;
  border-radius: 50%;
  padding: 11px;
  max-width: 50px;
  max-height: 50px;
  position: fixed;
  left: 50%;
  //top: -14px;
  bottom: 28px;
  transform: translate(-50%, 0px);
`;

const Label = styled.div`
  margin-top: 1px;
  font-size: 10px;
  text-transform: uppercase;
  z-index: 1;
`;
