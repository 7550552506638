import { useEffect } from "react";

interface OutsideClickOptions {
  ignoreToasts?: boolean;
}

const useOutsideClick = (
  refs: any,
  handleClick: () => void,
  options?: OutsideClickOptions
) => {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (options && options.ignoreToasts) {
        const toasts = document.querySelectorAll(".Toastify__toast");

        for (const toast of toasts) {
          if (toast.contains(event.target as Element)) return;
        }
      }
      if (refs.current && !refs.current.contains(event.target)) {
        handleClick();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    //document.addEventListener('touchstart', handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      //document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [handleClick, refs]);
};

export default useOutsideClick;
