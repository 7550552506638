import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import {useGetGameListMutation, useGetTvGamesListMutation} from "../../redux/api/gamesApi";
import {useTypedSelector} from "../../hooks/useTypedRedux";
import ImageIcon from "../../ui/material-kit/ImageIcon";
import InfiniteLoader from "react-window-infinite-loader";
import {FixedSizeGrid, VariableSizeGrid} from "react-window";
import {ReactWindowScroller} from "../../helpers/ReactWindowScroller";
import {IGame} from "../../redux/types/gamesTypes";
import useDimensions from "react-cool-dimensions";
import useMediaQuery from "../../hooks/useMediaQuery";
import {device} from "../../constants/deviceSizes";
import openGame from "../../helpers/openGame";
import GameCard from "../../ui/GameCard";
import InfiniteScroll from "react-infinite-scroll-component";
import {gamesPageSelector, tvGamesPageSelector} from "../../redux/selectors/gamesSelector";

interface ITvGamesPageContainer {
    value: {
        game?: string,
        provider?: string
    }
}

const MemoGameCard = React.memo(({item, minHeight}: { item: IGame, minHeight: number }) => {
    return (
        <Game>
            <GameCard isTv={true} game={item} style={{minHeight: minHeight / 2}} isLazy={true}/>
        </Game>
    )
})

const TvGamesPageContainer = (props: ITvGamesPageContainer) => {
    const {game, provider} = props.value;
    const [getTvGamesList, {isLoading}] = useGetTvGamesListMutation();

    const {data, countGames} = useTypedSelector(tvGamesPageSelector)

    const [page, setPage] = useState(1)

    useEffect(() => {
        setPage(2);
        getTvGamesList({
            data:
                {
                    type: 'update',
                    page: 1,
                    title: game,
                    provider,
                    size: 20
                }
        })
    }, [game, provider])

    const [hasMoreItems, setHasMoreItems] = useState(true);
    const loadMore = useCallback(() => {
        if (data.length >= countGames) {
            setHasMoreItems(false);
            return;
        }
        setHasMoreItems(true);
        let newPage = page + 1;
        setPage(newPage);
        getTvGamesList({
            data: {
                page,
                title: game,
                provider,
                size: 20
            }
        })
    },[countGames, data.length, game, getTvGamesList, page, provider]);

    const tablet = useMediaQuery(device.tabletM)
    const {observe, width} = useDimensions({});
    const minHeight = useMemo(() => {
        const count = tablet ? 2 : 4
        return width / count
    }, [width])

    const loader = useMemo(() => <></>, [])

    const stylesInfinite = useMemo(() => {
        return {
            display: "grid",
            gridTemplateColumns: !tablet ? 'repeat(4,1fr)' : 'repeat(2,1fr)',
            gridGap: '4px',
            height: '100%',
            overflow: 'hidden',
            gridAutoRows: 'min-content'
        }
    }, [tablet])

    return (
        <Wrapper ref={observe}>
            <InfiniteScroll
                dataLength={data.length}

                next={loadMore}
                style={stylesInfinite}
                hasMore={hasMoreItems && data.length !== 0}
                loader={loader}
            >
                {data.map((item, index) =>
                    <React.Fragment key={`game-${index}`}>
                        <MemoGameCard item={item} minHeight={minHeight}/>
                    </React.Fragment>)}
            </InfiniteScroll>
        </Wrapper>
    );
};

export default TvGamesPageContainer;

const Wrapper = styled.div`
  max-width: calc(100vw - 10px);
`

const Game = styled.div`
  width: inherit;
  padding: 4px;
  box-sizing: border-box;
  border-radius: 10px;
`


const List = styled(FixedSizeGrid)<{ index?: number }>`
  overflow: visible !important;
`