import styled from "styled-components";
import favorite from "assets/images/navbar/megawin-navbar/favorite.webp";
import favoriteActive from "assets/images/navbar/megawin-navbar/favorite_active.webp";
import mask from "./mask.svg";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "hooks/useTypedRedux";
import { useCustomToast } from "hooks/useCustomToast";
import { useNavigate } from "react-router-dom";
import { NavbarItem } from "./NavbarItem";

interface MobileNavbarProps {}

// todo - как будет свободное время, убрать FooterMobile и подогнать этот компонент под все проекты
export const MobileNavbar = (props: MobileNavbarProps) => {
  const { t, i18n } = useTranslation();
  const isFrench = i18n.language === "fr";

  const navigate = useNavigate();

  const { user } = useTypedSelector((state) => {
    return {
      user: state.userSlice.user,
    };
  });

  const callToast = useCustomToast({
    text: t("messages.alert.please_login"),
    type: "error",
  });

  const isActive = (path: string) => {
    let pathname = window.location.pathname.toLowerCase();
    let itemPath = path.toLowerCase();
    let search = window.location.search.toLowerCase();
    const exclude = ["category=crash", "category=virtual", "category=favorite"];
    if (exclude.some((item) => search.includes(item))) {
      let value = exclude.find((item) => search.includes(item));
      return value ? itemPath.includes(value) : false;
    }
    return (
      itemPath.includes(pathname) &&
      pathname !== "/" &&
      !exclude.some((item) => itemPath.includes(item))
    );
  };

  return (
    <NavbarContainer>
      <TestNavbar>
        <NavbarItem
          iconPath={"assets/images/navbar/megawin-navbar/games.webp"}
          activeIconPath={
            "assets/images/navbar/megawin-navbar/games_active.webp"
          }
          isFrench={isFrench}
          titleKey="slots"
          isActive={isActive("/games")}
          onClick={() => navigate("/games")}
        />

        <NavbarItem
          iconPath={"assets/images/navbar/megawin-navbar/live_casino.webp"}
          activeIconPath={
            "assets/images/navbar/megawin-navbar/live_casino_active.webp"
          }
          isFrench={isFrench}
          titleKey="live_casino"
          isActive={isActive("/tv-games")}
          onClick={() => navigate("/tv-games")}
        />

        <NavbarItem
          isFrench={isFrench}
          titleKey="favorite"
          isActive={isActive("/games?category=favorite")}
          onClick={() => {
            if (user) navigate("/games?category=favorite");
            else callToast();
          }}
        />

        <NavbarItem
          iconPath={"assets/images/navbar/megawin-navbar/crash.webp"}
          activeIconPath={
            "assets/images/navbar/megawin-navbar/crash_active.webp"
          }
          isFrench={isFrench}
          titleKey="crash"
          isActive={isActive("/games?category=Crash")}
          onClick={() => navigate("/games?category=Crash")}
          imageStyle={{ width: 50 }}
        />

        <NavbarItem
          iconPath={"assets/images/navbar/megawin-navbar/virtual.webp"}
          activeIconPath={
            "assets/images/navbar/megawin-navbar/virtual_active.webp"
          }
          isFrench={isFrench}
          titleKey="virtual"
          isActive={isActive("/games?category=Virtual")}
          onClick={() => navigate("/games?category=Virtual")}
        />
      </TestNavbar>
      <CentralCircle>
        <div
          onClick={() => {
            if (user) navigate("/games?category=favorite");
            else callToast();
          }}
        >
          <img
            src={
              isActive("/games?category=favorite") ? favoriteActive : favorite
            }
          />
        </div>
      </CentralCircle>
    </NavbarContainer>
  );
};

const TestNavbar = styled.div`
  width: 100%;
  height: 64px;
  mask-image: url(${mask}), linear-gradient(to left, #9747ff, #9747ff);
  mask-repeat: no-repeat;
  mask-position: calc(50% + 0.5px) -17px, 0 0;
  mask-size: 56px, 100%;
  mask-composite: exclude;
  background: linear-gradient(to left, #2b2a33, #2b2a33);
  position: absolute;
  bottom: 0;
  border-radius: 20px 20px 0 0;
  border: 1px solid #9747ff;
  border-bottom: none;

  display: flex;
  justify-content: space-between;

  > * {
    min-width: 60px;
  }

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    background-color: #9747ff;
    mask-image: url(${mask}), linear-gradient(to left, #9747ff, #9747ff);
    mask-repeat: no-repeat;
    mask-size: 58px, 100%;
    mask-position: calc(50% + 0.5px) -19px, 0 0;
    mask-composite: intersect;
    position: absolute;
    z-index: -1;
  }
`;

const CentralCircle = styled.div`
  width: 50px;
  height: 50px;
  border: 1px solid #9747ff;
  border-radius: 50%;
  background-color: #2b2a33;
  z-index: 100;
  box-sizing: border-box;
  cursor: pointer;

  img {
    width: 48px;
    height: 48px;
  }
`;

const NavbarContainer = styled.div`
  width: 100vw;
  bottom: 0;
  height: 79px;
  position: fixed;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: start;
  box-sizing: border-box;
`;
