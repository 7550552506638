import React from "react";
import { ReactComponent as PrivacyIcon } from "assets/images/footer_privacy_icon.svg";
import { ReactComponent as FacebookIcon } from "assets/images/header_facebook_logo.svg";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { getStorage } from "../../../hooks/useStorage";

const DrawerFooter = (): JSX.Element => {
  const { t } = useTranslation();

  const fields = {
    privacy: t(`common.footer.privacy`, {
      platform: getStorage("APP_SEO.TITLE"),
    }),
  };

  return (
    <Wrapper>
      <FacebookIcon style={{ width: 42, height: 42 }} />
      {fields.privacy}
      <PrivacyIcon style={{ width: 62, height: 62 }} />
    </Wrapper>
  );
};

export default DrawerFooter;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 42px 1fr 62px;
  grid-gap: 18px;
  width: 100%;
  text-transform: uppercase;
  align-items: center;
  font-size: 14px;
`;
