import React, { useEffect, useMemo, useState } from "react";
import styled, { CSSProperties, css } from "styled-components";
import Modal from "ui/material-kit/Modal";
import Button from "ui/material-kit/Button";
import Input from "ui/material-kit/Input";
import useInput from "hooks/useInput";
import { t } from "i18next";
import useTestValidation from "../../hooks/useTestValidation";
import useValidate from "../../hooks/useValidate";
import { validateExpression } from "../../constants/helperValidation";
import { useLoginMeMutation } from "../../redux/api/userApi";
import { ContentModal } from "../../ui/containers";
import ImageIcon from "../../ui/material-kit/ImageIcon";
import { getStorage } from "../../hooks/useStorage";
import { regularExpression } from "../../constants/regExp";
import useKeyPress from "hooks/useKeyPress";

interface ISingInModal {
  isOpen: boolean;
  onClose: (e?: any) => void;
  isFullScreen?: boolean;
}

const projectName = process.env.REACT_APP_INSTANCE;

const SignInModal = (props: ISingInModal): JSX.Element => {
  const { isOpen, onClose, isFullScreen } = props;

  const logo = useMemo(() => {
    const PATH = getStorage("APP_SEO.LOGO_PATH");
    return require(`../../${PATH}`);
  }, []);

  const fields = {
    username: t(`common.placeholder.username`),
    password: t(`common.placeholder.password`),
    sing_in: t(`common.modals.sing_in.sing_in`),
    login_now: t(`common.modals.sing_in.login_now`),
  };

  const [loginMe, { isLoading }] = useLoginMeMutation();
  const username = useInput("");
  const password = useInput("");

  useKeyPress(
    "Enter",
    () => {
      if (isOpen) handleSend();
    },
    [isOpen]
  );

  const checkValidate = useTestValidation({
    validateObject: {
      usernameValidate: useValidate(username.value, validateExpression.login),
      passwordValidate: useValidate(
        password.value,
        validateExpression.password
      ),
    },
    callback: () =>
      loginMe({
        data: {
          login: username.value,
          password: password.value,
        },
      }),
    callbackSuccess: () => {
      checkValidate.clear();
      onClose();
    },
  });

  const handleSend = () => {
    checkValidate.checkValidation();
  };

  const styleModal = (): any => {
    return (
      isFullScreen &&
      window.location.pathname &&
      (window.location.pathname === "/sport" ||
        window.location.pathname === "/live-sport")
    );
  };

  return (
    <Modal
      style={styleModal() ? { height: "100%", marginTop: 88 } : {}}
      backIcon={false}
      title={isFullScreen ? undefined : fields.login_now}
      isOpen={isOpen}
      onClose={onClose}
      isFullScreen={isFullScreen}
      extra={isFullScreen ? "background-color: unset" : ""}
      ignoreToasts
    >
      <ContentModal
        padding={!isFullScreen ? "0px 42px" : ""}
        isFullScreen={isFullScreen}
        width={isFullScreen ? "100%" : "496px"}
      >
        {isFullScreen && (
          <ImageIcon
            style={{
              width: projectName === "mybet" ? 312 : 300,
              height: projectName === "mybet" ? 32 : 70,
              margin:
                projectName === "megawin" ? "125px 0 30px" : "134px 0 18px",
            }}
            src={logo}
            alt={"desktop-logo"}
          />
        )}
        <Input
          {...username}
          placeholder={fields.username}
          {...checkValidate.validate["usernameValidate"]}
          regex={regularExpression.isSpace}
        />
        <Input
          {...password}
          type={"password"}
          regex={regularExpression.isSpace}
          placeholder={fields.password}
          {...checkValidate.validate["passwordValidate"]}
          onEnterPress={() => handleSend()}
        />
        {!isFullScreen && projectName !== "megawin" && <div />}
        <StyledButton
          isFullScreen={isFullScreen}
          onClick={() => handleSend()}
          isLoading={isLoading}
          id={`sing-in-modal`}
        >
          {fields.sing_in}
        </StyledButton>
      </ContentModal>
    </Modal>
  );
};

export default React.memo(SignInModal);

const StyledButton = styled(Button)<{ isFullScreen: boolean | undefined }>`
  width: 153px;
  text-transform: uppercase;

  ${({ isFullScreen }) =>
    projectName === "megawin" &&
    css`
      margin-top: ${isFullScreen ? "0px" : "8px"};
      margin-bottom: 18px;
    `}
`;
