import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userSlice from "./slices/userSlice";
import appSlice from "./slices/appSlice";
import { userAPI } from "./api/userApi";
import { appAPI } from "./api/appApi";
import transactionSlice from "./slices/transactionSlice";
import { transactionAPI } from "./api/transactionApi";
import gamesSlice from "./slices/gamesSlice";
import { gamesAPI } from "./api/gamesApi";
import RtkCommonMiddleware from "./middlewares";
import { hallOfFameApi } from "./api/hallOfFameApi";
import hallOfFameSlice from "./slices/hallOfFame";

const rootReducer = combineReducers({
  userSlice,
  appSlice,
  transactionSlice,
  gamesSlice,
  hallOfFameSlice,
  [userAPI.reducerPath]: userAPI.reducer,
  [appAPI.reducerPath]: appAPI.reducer,
  [transactionAPI.reducerPath]: transactionAPI.reducer,
  [gamesAPI.reducerPath]: gamesAPI.reducer,
  [hallOfFameApi.reducerPath]: hallOfFameApi.reducer,
});

export let store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      userAPI.middleware,
      appAPI.middleware,
      transactionAPI.middleware,
      gamesAPI.middleware,
      hallOfFameApi.middleware,
      RtkCommonMiddleware
    ),
});

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;
export const appDispatch = store.dispatch;

export type AppSelector<Return> = (state: RootState) => Return;
