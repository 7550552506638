import DesktopWrapper from "../../components/profile/DesktopWrapper";
import { PersonalDataInfo } from "../../components/profile/PersonalDataWrapper";

const PersonalDataDesktop = (): JSX.Element => {
  return (
    <DesktopWrapper
      title={"PROFILE_PERSONAL_DATA"}
      childrenInfo={<PersonalDataInfo />}
    />
  );
};

export default PersonalDataDesktop;
