import { DefaultTheme } from "styled-components";
import ITheme from "./ITheme";

const theme: ITheme = {
  hall_of_fame: {
    text_color: "#fff",
    secondary_text_color: "#79531F",
    third_text_color: "#906325",
    main_color: "#4D2608",
    border_color: "#724E1D",
    background_color: "#202020",
    secondary_background_color: "#A1712B",
    third_background_color: "#C89D4B",
    background_hover: "#FFF6B1",
    font_size_primary: "24px",
    font_size_mobile_primary: "12px",
  },
  background: {
    main: "#2B2A33",
    gradient: "linear-gradient(180deg, #BD93FF 0%, #9747FF 100%)",
    second: "#202020",
    sidebar: "#1A1A1A",
    header: "#1A1A1A",
    mobileHeader: "#000",
    secondHeader: "#000",
  },
  primary: {
    hover: "#c7a3ff",
    main: "#B98DFF",
    light: "#B98DFF",
    black: "#000000",
  },
  secondary: {
    main: "#363636",
    focused: "#5F5F5F",
    disabled: "#A1A1A1",
  },
  text: {
    white: "#FFFFFF",
    black: "#1A1A1A",
    listMenu: "#FFFFFF",
  },
  success: {
    main: "#C89D4B",
  },
  error: {
    main: "#CB2011",
  },
  warning: {
    main: "#202020",
  },
  main_card: {
    background:
      "linear-gradient(139deg, #472686 0%, #000 40%, #03080b 63%, #1c4360 100%)",
  },
  icons: {
    input: "#5F6368",
  },
};

const megawinTheme: DefaultTheme = theme;

export default megawinTheme;
